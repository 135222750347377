<script>
	import { _, locale } from 'svelte-i18n';
	import { data as profileData } from '../../stores';
	import { dedupe, updateAccounts } from '../../utils/helper';
	import * as sort from '../../utils/sort';
	import { flip } from 'svelte/animate';
	import { slide } from 'svelte/transition';
	import RemoveIconButton from '../button/RemoveIconButton.svelte';
	import Modal from '../modal/Modal.svelte';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
	import parser from 'ua-parser-js';
	import MobileIcon from '../icon/MobileIcon.svelte';
	import TabletIcon from '../icon/TabletIcon.svelte';
	import DesktopIcon from '../icon/DesktopIcon.svelte';
	import AppleIcon from '../icon/AppleIcon.svelte';
	import AndroidIcon from '../icon/AndroidIcon.svelte';
	import WindowsIcon from '../icon/WindowsIcon.svelte';
	import LinuxIcon from '../icon/LinuxIcon.svelte';
	import RemoveButton from '../button/RemoveButton.svelte';
	import { deleteDevice, getProfile } from '../../utils/api-calls';
	import 'dayjs/locale/ar';
	import 'dayjs/locale/fr';
	import 'dayjs/locale/es';
	import 'dayjs/locale/hi';
	import 'dayjs/locale/de';

	dayjs.extend(preParsePostFormat);
	dayjs.extend(relativeTime);

	export let editMode = false;

	const flip_animation_ms = 2000;
	let confirmDeviceDelete;

	let removeDeviceAjax = false;
	async function removeDevice() {
		try {
			removeDeviceAjax = confirmDeviceDelete;
			await deleteDevice(confirmDeviceDelete);
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmDeviceDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeDeviceAjax = null;
		}
	}
</script>

<section id="devices" class="mt-10">
	<div class="flex items-center justify-between">
		<h2 class="font-medium text-lg">{$_('Devices')}</h2>
		<!-- <div class="inline-flex items-center">
            <span class="text-sm">No Login Prompt</span>
            <HelpButton
                ariaLabel="Help"
                content={$_('Remembers your login choice on each device')}
                placement="top"
            />
        </div> -->
	</div>

	{#if $profileData.profile.devices?.length}
		{@const devices = $profileData.profile?.devices?.filter(
			(i) =>
				i.id === $profileData.profile?.currentDevice ||
				(i.createdAt?.at && i.lastAccess?.at && i.createdAt?.at !== i.lastAccess?.at)
		)}
		<ul class="mt-2 space-y-4">
			{#each dedupe(devices, 'id').sort( (a, b) => sort.devices($profileData.profile.currentDevice, a, b) ) as device (device.id)}
				<li
					class="flex relative items-start"
					animate:flip={{ duration: flip_animation_ms }}
					transition:slide|local
				>
					{#if confirmDeviceDelete && confirmDeviceDelete === device.id}
						<Modal
							position="left"
							class="md:min-w-[22rem] top-10"
							on:close={() => (confirmDeviceDelete = null)}
						>
							<div class="flex flex-col md:flex-row items-center justify-between">
								<span>{$_('Confirm Action')}</span>
								<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
									<button
										on:click={() => (confirmDeviceDelete = null)}
										class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
									>
									<RemoveButton
										on:click={removeDevice}
										isLoading={removeDeviceAjax === device.id}
										value="Forget"
									/>
								</div>
							</div>
						</Modal>
					{/if}

					<div class="w-6 h-6 flex-shrink-0 mt-1">
						{#if editMode && $profileData.profile.currentDevice !== device.id}
							<RemoveIconButton on:click={() => (confirmDeviceDelete = device.id)} />
						{/if}
					</div>

					<span class="-mt-1 w-12 h-12 relative inline-flex items-center justify-center">
						{#if parser(device.created.userAgent).os?.name?.includes('Mac') || parser(device.created.userAgent).os?.name?.includes('iOS')}
							<span class="absolute">
								<AppleIcon />
							</span>
						{:else if parser(device.created.userAgent).os?.name?.includes('Android')}
							<span class="absolute">
								<AndroidIcon />
							</span>
						{:else if parser(device.created.userAgent).os?.name?.includes('Linux')}
							<span class="absolute">
								<LinuxIcon />
							</span>
						{:else if parser(device.created.userAgent).os?.name?.includes('Windows')}
							<span
								class="absolute"
								class:mb-2={parser(device.created.userAgent).device?.type == undefined}
							>
								<WindowsIcon />
							</span>
						{/if}

						{#if parser(device.created.userAgent).device?.type === 'mobile'}
							<MobileIcon />
						{:else if parser(device.created.userAgent).device?.type === 'tablet'}
							<TabletIcon />
						{:else}
							<DesktopIcon />
						{/if}
					</span>

					<div class="{$locale && $locale.startsWith('ar') ? 'mr-2' : 'ml-2'} flex flex-col">
						<span
							>{parser(device.created.userAgent).browser?.name}
							{parser(device.created.userAgent).os?.name}</span
						>
						<span class="text-xs italic">
							{$_('First login {date}', {
								values: {
									date: dayjs(device.createdAt).locale($locale).fromNow()
								}
							})}

							{#if device.created?.location?.City || device.created?.location?.Country}
								•
								{device.created?.location?.City ? `${device.created?.location?.City},` : ''}
								{device.created?.location?.Country}
							{/if}
						</span>
						<span
							class="text-xs italic"
							class:font-semibold={$profileData.profile.currentDevice === device.id}
						>
							{#if $profileData.profile.currentDevice === device.id}
								{$_('Current device')}
							{:else if device.lastAccess}
								{$_('Last login {date}', {
									values: {
										date: dayjs(device.lastAccess?.at).locale($locale).fromNow()
									}
								})}
							{:else}
								{$_('Last login {date}', {
									values: {
										date: dayjs(device.createdAt).locale($locale).fromNow()
									}
								})}
							{/if}

							{#if device.lastAccess?.location.City || device.created?.location?.City || device.lastAccess?.location?.Country || device.created?.location?.Country}
								•
								{device.lastAccess?.location.City
									? `${device.lastAccess?.location.City},`
									: `${device.created?.location.City},`}
								{device.lastAccess?.location?.Country || device.created?.location?.Country}
							{/if}
						</span>
					</div>

					<!-- <input
                        on:click={(e) => togglePrompt(e.target.checked, device.id)}
                        type="checkbox"
                        name="no_prompt"
                        checked={device.noPrompt}
                        class="form-checkbox"
                    /> -->
				</li>
			{/each}
		</ul>
	{:else}
		<span class="text-fallback text-sm mt-2">{$_('No devices')}</span>
	{/if}
</section>
