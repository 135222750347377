<script>
	import { onMount } from 'svelte';
	import { _ } from 'svelte-i18n';
	import TitleBar from '../../../lib/TitleBar.svelte';
	import {
		// promptForPasskey,
		logPlausibleEvent,
		setAttributes
	} from '../../../utils/helper.js';
	import Notification from '../../../lib/Notification.svelte';
	import { showSpinner, data, notification } from '../../../stores';
	import {
		getCreateChallenge,
		postNoPromptPasskey,
		postCreateChallenge
	} from '../../../passkey.js';
	import { startRegistration } from '@simplewebauthn/browser';
	import { getProfile } from '../../../utils/api-calls';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import SpinnerIcon from '../../../lib/icon/SpinnerIcon.svelte';
	import PasskeyIcon from '../../../lib/icon/PasskeyIcon.svelte';

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
			} catch {
				return replaceRoute('/login');
			}
		}

		if (!$data.isPersonalLoggedIn && !$data?.isManagedLoggedIn) return replaceRoute('/login');

		if (!$data?.preferred?.length) return replaceRoute('/wizard/preferredprovider');

		//uncomment to start prompting for passkey
		// const showPromptForPasskey = await promptForPasskey($data);
		// if (!showPromptForPasskey) return replaceRoute('/');

		logPlausibleEvent({ u: '/passkey' });
		$showSpinner = false;
	});

	let passkeyAjax = false;
	async function createPasskey() {
		try {
			passkeyAjax = true;
			const createCredentialOpts = await getCreateChallenge();
			const createCredentialRes = await startRegistration(createCredentialOpts);
			const postCredentialRes = await postCreateChallenge(createCredentialRes);
			if (!postCredentialRes?.verified) throw postCredentialRes;
			$data = await getProfile();
			setTimeout(() => {
				//so it animates in
				$notification = {
					text: 'Hellō Passkey created on this device',
					type: 'success'
				};
			}, 150);
			return navigateTo('/');
		} catch (err) {
			console.error(err);
			// TODO: each await block try catch
			// Improve error notification here
			$notification = {
				text: 'Authentication cancelled',
				type: 'error'
			};
			passkeyAjax = false;
		}
	}

	async function noPromptPasskey() {
		try {
			await postNoPromptPasskey();
			$data = await getProfile();
			return navigateTo('/');
		} catch (err) {
			console.error(err);
		}
	}

	function remindLater() {
		sessionStorage.setItem('remindPasskeyPromptLater', true);
		return navigateTo('/');
	}
</script>

<TitleBar />

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	<main
		class="py-4 stack:py-[5vh] [@media(min-height:1216px)]:!py-16 flex flex-col items-center gap-x-col flex-1"
	>
		<div>
			<PasskeyIcon css="mx-auto h-9" />

			<h1 class="text-center flex-1 border-none text-lg font-medium my-2">
				{$_('Use a passkey for faster, safer log in')}
			</h1>
			<p class="text-sm text-center">{$_('Log in using your face, fingerprint, or device PIN')}</p>

			<div class="space-y-3 my-6 mx-auto max-w-xs text-sm">
				<button
					on:click={createPasskey}
					disabled={passkeyAjax}
					class="btn-background w-full h-10 flex items-center justify-center"
				>
					{#if passkeyAjax}
						<SpinnerIcon css="h-5 w-5 block mx-auto" />
					{:else}
						<PasskeyIcon css="h-4 w-4" />
						<span class="ml-2">{$_('Create Hellō Passkey')}</span>
					{/if}
				</button>
				<button on:click={remindLater} class="btn-border w-full h-10"
					>{$_('Remind me later')}</button
				>
			</div>
			<button
				on:click={noPromptPasskey}
				class="hover:underline focus:underline text-sm block mx-auto"
				>{$_("Don't ask again on this device")}</button
			>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}
