<script>
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import TitleBar from '../../../lib/TitleBar.svelte';
	import { data, showSpinner, notification } from '../../../stores.js';
	import { getProfile, keepAlive } from '../../../utils/api-calls.js';
	import ProvidersAnimation from '../../../lib/animation/ProvidersAnimation.svelte';
	import Notification from '../../../lib/Notification.svelte';
	import {
		logPlausibleEvent,
		clearLocalAndSessionStorage,
		setAttributes,
		getDeviceTheme
	} from '../../../utils/helper.js';

	let welcomeAppInfo = {};
	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();

				if (!$data.isPersonalLoggedIn && !$data?.isManagedLoggedIn) {
					clearLocalAndSessionStorage();
					return replaceRoute('/login');
				}
			} catch {
				return replaceRoute('/login');
			}
		}

		localStorage.setItem('currentWizardStage', 'welcome');

		if (sessionStorage.welcome_app_info) {
			try {
				welcomeAppInfo = JSON.parse(sessionStorage.welcome_app_info);
			} catch (err) {
				console.error(err);
				sessionStorage.removeItem('welcome_app_info');
			}
		}

		logWizardFunnelPlausibleEvent();

		if (welcomeAppInfo.name) {
			//From welcome link
			logPlausibleEvent({ u: '/welcome' });
		} else {
			logPlausibleEvent({ u: '/wizard/welcome' });
		}

		$showSpinner = false;
	});

	function logWizardFunnelPlausibleEvent() {
		//Start of Wizard Funnel
		//wizard funnel state is not already sent + is in wizard
		const isInWizard = !$data?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		if (!sessionStorage.wiz_funnel && isInWizard) {
			const preferred = $data?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			logPlausibleEvent({
				n: 'Wiz Welcome',
				p: { preferred, welcome_email_app },
				u: '/wizard/welcome'
			});
			sessionStorage.setItem('wiz_funnel', 'wiz_welcome');
		}
	}

	function start() {
		keepAlive();
		const preferred = $data.profile?.accounts?.find((i) => i.preferred);
		const nonSocialProviders = ['email', 'phone', 'ethereum'];
		const isPreferredSocial = !nonSocialProviders.includes(preferred?.slug);
		const socialAccountLinked = $data.profile?.accounts.filter(
			(i) => ![...nonSocialProviders, 'ethereum'].includes(i.slug)
		).length;
		const recoveries = $data.profile?.accounts?.filter((i) => i.recovery && !i.preferred);
		if ($data.actions?.doneWizardAt) {
			return navigateTo('/');
		} else if (recoveries?.length >= 2 && !isPreferredSocial && socialAccountLinked) {
			return navigateTo('/wizard/upgrade');
		} else if ($data?.verifyManagedEmail) {
			return navigateTo('/wizard/email');
		} else if ($data?.chooseWhoManages || $data?.chooseManagedLogo) {
			return navigateTo('/wizard/managed');
		} else if (!$data?.preferred?.length) {
			return navigateTo('/wizard/preferredprovider');
		} else {
			return navigateTo('/wizard/status');
		}
	}

	const delay = 50;

	const handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			start();
		}
	};
</script>

<svelte:head>
	<title>Hellō - {$_('Welcome')}</title>
</svelte:head>

<svelte:window on:keydown={handleEnterKey} />

<TitleBar
	overrideTitle={$_('Welcome {name}', {
		values: {
			name: $data.profile?.nicknames?.[0] || $data.profile?.given_names?.[0]
		}
	})}
/>

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	<main class="flex-1 overflow-y-auto pb-16">
		<div class="mt-8">
			<div
				class="max-w-2xl mx-auto px-3 flex items-center justify-between space-x-2 md:space-x-0 text-sm"
				in:fade={{ delay: 0 * delay }}
			>
				<p class="text-center flex-1 border-none text-base md:text-xl">
					{$_(
						'You are now a member of Hellō, a free service that lets you log in with your preferred provider anywhere that accepts Hellō'
					)}
				</p>
			</div>
			<div class="px-3 md:px-0 md:max-w-2xl mx-auto mt-4 text-center">
				<div class="h-20 mt-10 relative invert-and-saturate" in:fade={{ delay: 1 * delay }}>
					<ProvidersAnimation />
				</div>

				<p class="text-base md:text-xl" in:fade={{ delay: 2 * delay }}>
					{#if welcomeAppInfo?.name}
						{$_(
							'Hellō is a layer between applications, such as {appName}, and providers giving you control and privacy',
							{ values: { appName: welcomeAppInfo?.name } }
						)}
					{:else}
						{$_(
							'Hellō is a layer between applications and providers giving you control and privacy'
						)}
					{/if}
				</p>

				<div class="relative h-20" in:fade={{ delay: 3 * delay }}>
					<div class="w-3/4 md:w-1/2 mx-auto my-8 relative">
						{#if getDeviceTheme() === 'dark' && welcomeAppInfo?.dark_image_uri && welcomeAppInfo?.dark_image_uri != 'undefined'}
							<div
								class="app-image resize md:w-[17%] md:top-5 md:left-1 md:h-10 absolute z-50"
								style="background-image: url({welcomeAppInfo?.dark_image_uri}); background-size: contain; background-repeat: no-repeat; background-position: center;"
							/>
						{:else if welcomeAppInfo?.image_uri && welcomeAppInfo?.image_uri != 'undefined'}
							<div
								class="app-image resize md:w-[17%] md:top-5 md:left-1 md:h-10 absolute z-50"
								style="background-image: url({welcomeAppInfo?.image_uri}); background-size: contain; background-repeat: no-repeat; background-position: center;"
							/>
						{/if}
						<img
							src="https://cdn.hello.coop/images/landing-page-graphic.png"
							alt="Hellō graphic"
							class="invert-and-saturate w-full"
						/>
					</div>
				</div>

				<p class="text-base md:text-xl mt-12" in:fade={{ delay: 4 * delay }}>
					{$_(
						'For example, Google will only see you have logged into Hellō if you choose Google as your preferred provider'
					)}
				</p>

				<h1
					class="mt-10 text-center flex-1 border-none text-xl md:text-2xl font-semibold"
					in:fade={{ delay: 5 * delay }}
				>
					{#if $data.actions?.doneWizardAt}
						{$_('You have already set up your wallet')}
					{:else if $data.profile?.accounts?.filter((i) => !i.preferred && i.recovery).length >= 2}
						{$_('You now have more control of your identity')}
					{:else}
						{$_("Let's set up wallet recovery")}
					{/if}
				</h1>

				<button
					on:click={start}
					data-test="welcome-continue-btn"
					class="btn-background mt-8 max-w-md text-lg w-full"
					in:fade={{ delay: 6 * delay }}
				>
					{#if $data.actions?.doneWizardAt || $data.profile?.accounts?.filter((i) => !i.preferred && i.recovery).length >= 2}
						{$_('Continue')}
					{:else}
						{$_('Start')}
					{/if}
				</button>
			</div>
		</div>
	</main>

	<wc-footer use:setAttributes />
{/if}

<style>
	@media (prefers-color-scheme: dark) {
		.invert-and-saturate {
			filter: invert(80%) saturate(0%);
		}

		.app-image {
			background-color: #404040;
		}
	}

	@media (prefers-color-scheme: light) {
		.invert-and-saturate {
			filter: saturate(0%);
		}

		.app-image {
			background-color: #eaeaea;
		}
	}

	@media only screen and (max-width: 768px) {
		.resize {
			width: 78px;
			left: 8px;
			height: 52px;
			top: 30px;
		}
	}

	@media only screen and (max-width: 640px) {
		.resize {
			width: 11.5vw;
			left: 1.5vw;
			height: 8.5vw;
			top: 4.2vw;
		}
	}
</style>
