<script>
	import { _ } from 'svelte-i18n';
	import { data as profileData, notification } from '../../stores';
	import { updateAccounts } from '../../utils/helper';
	import { deleteProvider, getProfile, deleteUnverifiedProvider } from '../../utils/api-calls';
	import PersonalProviderSection from './PersonalProviderSection.svelte';
	import ManagedProviderSection from './ManagedProviderSection.svelte';

	export let editMode = false;
	export let dropdownStates = {};
	export let verifyProvider = null;
	export let verifyProviderAuthority = null;
	export let handleDropdown = () => {};
	export let continueWithProvider = () => {};
	export let makePreferred = () => {};
	export let makeRecovery = () => {};
	export let removeRecovery = () => {};

	let confirmProviderDelete;

	let removeProviderAjax = false;
	async function removeProvider(id) {
		try {
			removeProviderAjax = confirmProviderDelete;
			const res = await deleteProvider(id);
			$profileData.profile.accounts = res.accounts;
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmProviderDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeProviderAjax = null;
		}
	}

	let removeUnverifiedProviderAjax = false;
	async function removeUnverifiedProvider(user_name) {
		try {
			removeUnverifiedProviderAjax = confirmProviderDelete;
			if ($profileData.profile.unverified_emails.includes(user_name)) {
				const index = $profileData.profile.unverified_emails.indexOf(user_name);
				await deleteUnverifiedProvider(index, 'email');
			} else {
				const index = $profileData.profile.unverified_phones.indexOf(user_name);
				await deleteUnverifiedProvider(index, 'phones');
			}
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmProviderDelete = null;
		} catch (err) {
			console.error(err);
		} finally {
			removeUnverifiedProviderAjax = null;
		}
	}

	async function verifyEmailSuccess() {
		try {
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			if (!$profileData.merge) {
				if (verifyProvider) {
					$notification = {
						text: $_('{contact} has been verified', {
							values: {
								contact: verifyProvider
							}
						}),
						type: 'success'
					};
				}
				verifyProvider = null;
			}
			dropdownStates.email = false;
			dropdownStates.managedProvider = false;
		} catch (err) {
			console.error(err);
		}
	}
</script>

<PersonalProviderSection
	{editMode}
	bind:dropdownStates
	bind:confirmProviderDelete
	bind:verifyProvider
	bind:verifyProviderAuthority
	{continueWithProvider}
	{removeProviderAjax}
	{removeUnverifiedProviderAjax}
	{handleDropdown}
	{removeProvider}
	{removeUnverifiedProvider}
	{verifyEmailSuccess}
	{removeRecovery}
	{makePreferred}
	{makeRecovery}
/>

<ManagedProviderSection
	{editMode}
	bind:dropdownStates
	bind:confirmProviderDelete
	{removeProviderAjax}
	{removeUnverifiedProviderAjax}
	{handleDropdown}
	{removeProvider}
	{removeUnverifiedProvider}
	{verifyEmailSuccess}
	{removeRecovery}
	{makeRecovery}
/>
