<script>
	import { onMount, onDestroy } from 'svelte';
	import { isLoading } from 'svelte-i18n';
	import Router, { replace as replaceRoute } from 'svelte-spa-router';
	import { _, locale } from 'svelte-i18n';
	import routes from './pages/index/index.js';
	import {
		data,
		showSpinner,
		showUpgradePreferredModal,
		countdown,
		notification,
		mergeAccounts
	} from './stores.js';
	import UpgradePreferredModal from './lib/modal/UpgradePreferredModal.svelte';
	import SessionTimerModal from './lib/modal/SessionTimerModal.svelte';
	import { SESSION_EXPIRE_SHOW_MODAL_MS } from './constants.js';
	import MergeModal from './lib/modal/MergeModal.svelte';
	import { logout, getProfile, postClientError } from './utils/api-calls';

	let showSessionExpiredModal = false;

	onMount(() => {
		//Remove load spinner in HTML page
		document.getElementById('load-spinner')?.remove();
		//Show Svelte spinner
		$showSpinner = true;

		//https://stackoverflow.com/a/75961014/9747630
		window.addEventListener('unhandledrejection', postClientError);
		window.addEventListener('error', postClientError);
	});

	onDestroy(() => {
		window.removeEventListener('unhandledrejection', postClientError);
		window.removeEventListener('error', postClientError);
	});

	$: if ($countdown <= SESSION_EXPIRE_SHOW_MODAL_MS / 1000) {
		showSessionExpiredModal = true;
		if ($countdown <= 0) {
			countdown.stop();
			logoutUser().finally(() => (showSessionExpiredModal = false));
		}
	}

	async function logoutUser() {
		try {
			await logout();
			$data = await getProfile();
		} catch (err) {
			console.error(err);
		} finally {
			replaceRoute('/login');
			$notification = {
				text: $_('You have been logged out'),
				type: 'error'
			};
		}
	}

	$: {
		if ($locale && $locale.startsWith('ar')) {
			document.dir = 'rtl';
		} else {
			document.dir = 'ltr';
		}
	}

	// $: console.info('Session expires in', $countdown);
</script>

{#if $isLoading || $showSpinner}
	<div class="spinner" />
{/if}

{#if !$isLoading}
	<div class="flex flex-col h-full">
		{#if $mergeAccounts}
			<MergeModal />
		{/if}

		{#if $showUpgradePreferredModal}
			<UpgradePreferredModal />
		{/if}

		{#if showSessionExpiredModal}
			<SessionTimerModal on:close={() => (showSessionExpiredModal = false)} />
		{/if}

		<Router {routes} />
	</div>
{/if}
