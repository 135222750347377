<script>
	import { _ } from 'svelte-i18n';
	import { push as navigateTo, replace as replaceRoute } from 'svelte-spa-router';
	import Modal from '../../../lib/modal/Modal.svelte';
	import VerifyOTP from '../../../lib/VerifyOTP.svelte';
	import { data, showSpinner, notification } from '../../../stores.js';
	import { onMount } from 'svelte';
	import Notification from '../../../lib/Notification.svelte';
	import TitleBar from '../../../lib/TitleBar.svelte';
	import { getProfile, postVerifyEmail, postVerifyEmailCode } from '../../../utils/api-calls';
	import { logPlausibleEvent, dedupe, setAttributes } from '../../../utils/helper.js';
	import SpinnerIcon from '../../../lib/icon/SpinnerIcon.svelte';

	let value = '';
	let showOTPModal = false;
	let ajaxRequest = false;
	let managed = {};

	let ajaxRequestSendOTP = false;
	let ajaxRequestResendOTP = false;
	let otp = '';

	let selectedManagedEmail;

	onMount(async () => {
		$showSpinner = true;

		//we dont have profile data
		if (!$data?.version) {
			try {
				$data = await getProfile();
			} catch {
				return replaceRoute('/login');
			}
		}

		//we already verified
		if (!$data?.verifyManagedEmail) return replaceRoute('/');

		managed = $data?.verifyManagedEmail;

		if (managed) selectedManagedEmail = $data?.verifyManagedEmail.emails[0];

		logPlausibleEvent({ u: '/wizard/email' });

		$showSpinner = false;
	});

	async function verifyEmail(resend = false) {
		try {
			ajaxRequest = true;
			if (resend) {
				await postVerifyEmail(value || selectedManagedEmail, true);
			} else {
				await postVerifyEmail(value || selectedManagedEmail);
			}

			showOTPModal = selectedManagedEmail;

			if (resend) otp = '';
		} catch (err) {
			if (err.status === 401) {
				const { error } = await err.json();
				if (error === 'EMAIL_ALREADY_VERIFIED') {
					$notification = {
						text: $_('{contact} has already been verified', {
							values: {
								contact: value
							}
						}),
						type: 'error'
					};
				}
			} else {
				console.error(err);
			}
		} finally {
			ajaxRequest = ajaxRequestResendOTP = false;
		}
	}

	async function verifyEmailCode(code) {
		try {
			ajaxRequestSendOTP = true;
			await postVerifyEmailCode(code);
			$data = await getProfile();
			$notification = {};
			if (!$data?.merge) {
				navigateTo('/');
			}
		} catch (err) {
			console.error(err);
			otp = '';
		} finally {
			ajaxRequestSendOTP = false;
		}
	}
</script>

<TitleBar showMenu={false} />

{#if $notification.text}
	<Notification />
{/if}

{#if !$showSpinner}
	<div class="flex-1 overflow-y-auto">
		<div class="px-outer max-w-[856px] w-full mx-auto">
			<main class="pt-6 pb-12 flex flex-col items-center gap-x-col">
				<div class="max-w-col w-full px-indent mt-2">
					<h1 class="text-lg">{$_('Please verify the email to be used with')}</h1>
					<h1 data-test="domain" class="text-lg font-semibold px-4">{managed?.domain}</h1>

					<div class="my-6 space-y-5">
						{#if Array.isArray(managed.emails) && [...new Set(managed.emails)].length > 1}
							<div
								class="bg-charcoal bg-opacity-10 dark:bg-opacity-100 space-y-2 p-2 rounded-md mt-2"
							>
								<div data-test="emails-container" class="relative flex items-center">
									<div class="w-full">
										<ul class="flex flex-col items-start space-y-2 mt-1">
											{#each dedupe(managed?.emails) as email, index (email)}
												<li data-test="email-{index}" class="flex items-center w-full relative">
													<input
														id="email-{email}"
														name="email"
														type="radio"
														bind:group={selectedManagedEmail}
														checked={index === 0}
														value={email}
														class="w-4 h-4 rounded-full form-radio text-charcoal dark:text-[#808080]"
													/>
													<label
														for="email-{email}"
														class="ml-2.5 inline-flex items-center truncate w-full"
													>
														<span class="truncate">
															{email}
														</span>
													</label>

													{#if showOTPModal === email}
														<Modal
															position="center"
															class="top-8"
															on:close={() => {
																showOTPModal = false;
																otp = '';
															}}
														>
															<VerifyOTP
																bind:otp
																bind:ajaxRequestSendOTP
																bind:ajaxRequestResendOTP
																verifyCode={verifyEmailCode}
																on:resend={() => verifyEmail(true)}
															/>
														</Modal>
													{/if}
												</li>
											{/each}
										</ul>
									</div>
								</div>
							</div>
						{:else}
							<div class="relative">
								<div
									class="px-4 overflow-hidden block h-12 flex items-center rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100"
								>
									{selectedManagedEmail}
								</div>
								{#if showOTPModal === selectedManagedEmail}
									<Modal
										position="center"
										class="top-10"
										on:close={() => {
											showOTPModal = false;
											otp = '';
										}}
									>
										<VerifyOTP
											bind:otp
											bind:ajaxRequestSendOTP
											bind:ajaxRequestResendOTP
											verifyCode={verifyEmailCode}
											on:resend={() => verifyEmail(true)}
										/>
									</Modal>
								{/if}
							</div>
						{/if}

						<button
							data-test="email-send-verification-btn"
							on:click={() => verifyEmail(false)}
							disabled={ajaxRequest}
							class="disabled:opacity-60 relative transition btn-background h-12 w-full inline-flex items-center justify-center"
						>
							{#if ajaxRequest}
								<SpinnerIcon css="h-5 w-5 text-white" />
							{:else}
								{$_('Send verification code')}
							{/if}
						</button>
					</div>
				</div>
			</main>
		</div>
	</div>

	<wc-footer use:setAttributes />
{/if}
