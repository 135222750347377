<!-- 
  Wallet profile page
  Root page of wallet SPA
-->

<script>
	import { _ } from 'svelte-i18n';
	import TitleBar from '../../lib/TitleBar.svelte';
	import { replace as replaceRoute } from 'svelte-spa-router';
	import { notification, data as profileData, showSpinner } from '../../stores.js';
	import { onMount } from 'svelte';
	import Notification from '../../lib/Notification.svelte';
	import LanguageSection from '../../lib/profile/LanguageSection.svelte';
	import {
		logPlausibleEvent,
		clearLocalAndSessionStorage,
		setAttributes,
		getDisplay,
		updateAccounts
	} from '../../utils/helper.js';
	import { getProfile, postLinkProvider, putPreferred, keepAlive } from '../../utils/api-calls.js';
	import ApplicationSection from '../../lib/profile/ApplicationSection.svelte';
	import DownloadDataSection from '../../lib/profile/DownloadDataSection.svelte';
	import DeviceSection from '../../lib/profile/DeviceSection.svelte';
	import NameSection from '../../lib/profile/NameSection.svelte';
	import PictureSection from '../../lib/profile/PictureSection.svelte';
	import ProviderSection from '../../lib/profile/ProviderSection.svelte';
	import ProfileLandingModal from '../../lib/modal/ProfileLandingModal.svelte';
	import InfoSection from '../../lib/profile/InfoSection.svelte';

	let editMode = false,
		confirmProviderDelete,
		verifyProvider = null,
		verifyProviderAuthority = null;

	let dropdownStates = {
		provider: false,
		managedProvider: false,
		language: false,
		picture: false,
		email: false,
		phone: false
	};

	let remoteAuthDone = false;

	let showAddNameModal = false;

	let showProfileLandingModal = false;

	let oauth_error = false;

	const searchParams = new URLSearchParams(window.location.search);
	const hashParams = new URLSearchParams(window.location.hash.substring(1));

	onMount(async () => {
		$showSpinner = true;

		const errorFromProvider = hashParams.get('error');
		const responseFromProvider = hashParams.get('provider');
		const loadedFromWelcomeEmail = searchParams.has('welcome');

		if (errorFromProvider) {
			//LINE returns ACCESS_DENIED (uppercase)
			//Apple returns user_cancelled_authorize
			if (
				['access_denied', 'user_cancelled_authorize'].includes(errorFromProvider?.toLowerCase())
			) {
				$notification = {
					text: $_('Request cancelled'),
					type: 'error'
				};
			} else {
				$notification = {
					text: $_('Something went wrong. Please try again later.'),
					type: 'error'
				};
			}
			oauth_error = true;

			//User can cancel authorize flow on linking provider - make getProfile call since user can be still logged in
			try {
				$profileData = await getProfile('', { showNotification: !oauth_error });
			} catch (err) {
				return showLogin();
			}
		} else if (responseFromProvider) {
			try {
				$profileData = await getProfile(hashParams.toString(), { showNotification: !oauth_error });
				logProviderResPlausibleEvents();
			} catch (err) {
				console.error(err);
			}
		} else if (loadedFromWelcomeEmail) {
			try {
				$profileData = await getProfile(searchParams.toString(), {
					showNotification: !oauth_error
				});
			} catch {
				window.history.replaceState({}, document.title, window.location.pathname);
				return replaceRoute('/login');
			}

			//app info for welcome page and plausible events
			const welcome_app_info = {
				name: searchParams.get('name'),
				image_uri: searchParams.get('image_uri'),
				dark_image_uri: searchParams.get('dark_image_uri')
			};
			sessionStorage.setItem('welcome_app_info', JSON.stringify(welcome_app_info));
		} else if (!$profileData?.version) {
			//we dont have profile data
			try {
				$profileData = await getProfile('', { showNotification: !oauth_error });
			} catch {
				return showLogin();
			}
		}

		// const isProd = window.location.hostname === 'wallet.hello.coop'
		// if (!isProd && searchParams.has('die')) {
		// 	throw new Error('You have chosen....death!');
		// }

		//Done processing query/hash params the page got loaded with
		//Clear url query params (oauth errors, provider response, welcome email params)
		window.history.replaceState({}, document.title, window.location.pathname);

		//Authorization success at IAB
		if ($profileData.inApp) {
			remoteAuthDone = true;
			$notification = {
				text: 'Authorization is complete',
				type: 'success'
			};
			$showSpinner = false;
			return;
		}

		const hasPreferred = $profileData?.preferred?.length;
		const preferredLoggedIn = $profileData?.isPersonalLoggedIn;
		const isLoggedIn = $profileData?.isPersonalLoggedIn || $profileData?.isManagedLoggedIn;
		const currentWizardStage = localStorage.getItem('currentWizardStage');

		if (
			$profileData?.verifyManagedEmail ||
			$profileData?.chooseWhoManages ||
			$profileData?.chooseManagedLogo ||
			(isLoggedIn && !hasPreferred)
		) {
			if (!currentWizardStage && !$profileData?.actions?.doneWizardAt)
				return replaceRoute('/wizard/welcome');

			if ($profileData?.verifyManagedEmail) return replaceRoute('/wizard/email');

			if ($profileData?.chooseWhoManages || $profileData?.chooseManagedLogo)
				return replaceRoute('/wizard/managed');

			if (isLoggedIn && !hasPreferred) return replaceRoute('/wizard/preferredprovider');
		}

		if (
			((hasPreferred && preferredLoggedIn) || (!hasPreferred && isLoggedIn)) &&
			!$profileData.actions?.doneWizardAt
		) {
			//Determine next step in wizard after linking provider
			const WIZARD_STAGES = [
				'welcome',
				'preferredprovider',
				'status',
				'recoveryprovider',
				'incomplete',
				'upgrade'
			];
			if (WIZARD_STAGES.includes(currentWizardStage)) {
				// get our current state of preferred and recovery providers
				const preferred = $profileData.profile?.accounts?.find((i) => i.preferred);
				const UPGRADEABLE_PROVIDERS = ['email', 'phone'];
				const NON_RECOMMENDED_PROVIDERS = [...UPGRADEABLE_PROVIDERS, 'ethereum'];
				const isUpgradable = UPGRADEABLE_PROVIDERS.includes(preferred?.slug);
				const socialAccountLinked = $profileData.profile?.accounts.filter(
					(i) => !NON_RECOMMENDED_PROVIDERS.includes(i.slug) && !i.managed
				).length;
				const recoveries = $profileData.profile?.accounts.filter((i) => i.recovery && !i.preferred);
				if (recoveries?.length >= 2) {
					// we have all the recoveries we need
					if (isUpgradable && socialAccountLinked) {
						return replaceRoute('/wizard/upgrade');
					} else {
						return replaceRoute('/wizard/status');
					}
				}
				return replaceRoute('/wizard/' + currentWizardStage);
			}
			return replaceRoute('/wizard/' + WIZARD_STAGES[0]);
		}

		if (!$profileData.isPersonalLoggedIn) return showLogin();

		//uncomment to start prompting for passkey
		// const showPromptForPasskey = await promptForPasskey($profileData);
		// if (showPromptForPasskey) return replaceRoute('/wizard/passkey');

		//Handle outstanding make preferred flow after verification of provider
		let authority;
		try {
			authority = JSON.parse(sessionStorage.getItem('authority'));
		} catch (err) {
			console.warn('Bad data in authority session storage. Clearing...');
			sessionStorage.getItem('authority');
		}
		if (authority) {
			try {
				$showSpinner = true;
				const preferred = authority.preferred;
				const recovery = authority.recovery;
				const no_recovery = authority.no_recovery;
				const account = $profileData.profile?.accounts?.find(
					(i) => i.id === (preferred || recovery || no_recovery)
				);
				if (preferred) {
					await makePreferred(account);
				} else if (recovery) {
					await makeRecovery(account);
				} else if (no_recovery) {
					await removeRecovery(account);
				}
			} catch (err) {
				// nothing we can do to recover
				console.error(err);
			} finally {
				sessionStorage.removeItem('authority');
				$showSpinner = false;
			}
		}

		//Only add unverified emails/phone to accounts upon wizard completion
		if ($profileData.actions?.doneWizardAt) {
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
		}

		logPlausibleEvent({ u: '/' });

		if (localStorage.showProfileLandingModal) {
			showProfileLandingModal = true;
		}
		if (sessionStorage.isInEditMode) {
			editMode = true;
		}

		$showSpinner = false;
	});

	function handleDropdown(toggledDropdown) {
		for (const i in dropdownStates) {
			if (i === toggledDropdown) {
				dropdownStates[i] = !dropdownStates[i];
				continue;
			}
			dropdownStates[i] = false;
		}
	}

	function showLogin() {
		clearLocalAndSessionStorage();
		return replaceRoute('/login');
	}

	function logProviderResPlausibleEvents() {
		//End of Email Upgrade Funnel
		//email upgrade funnel state is valid and not already sent + log in success
		if (
			sessionStorage.email_upgrade_funnel === 'email_upgrade_start' &&
			$profileData.isPersonalLoggedIn
		) {
			const email_domain = $profileData?.preferred?.[0]?.user_name?.split('@')[1];
			logPlausibleEvent({
				n: 'Email Upgrade Success',
				p: { email_domain, slug: hashParams.get('provider') },
				u: '/'
			});
			sessionStorage.removeItem('email_upgrade_funnel');
		}

		//Wizard Funnel
		const isInWizard = !$profileData?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		if (isInWizard) {
			const preferred = $profileData?.preferred?.[0]?.slug;
			let welcome_email_app;
			if (sessionStorage.welcome_app_info) {
				try {
					welcome_email_app = JSON.parse(sessionStorage.welcome_app_info)?.name;
				} catch (err) {
					console.error(err);
				}
			}
			const recovery_1 = $profileData?.recovery?.[0]?.slug;
			const recovery_2 = $profileData?.recovery?.[1]?.slug;
			if (sessionStorage.wiz_funnel === 'wiz_recovery_1_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 1 Success',
					p: { preferred, welcome_email_app, recovery_1 },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_1_success');
			} else if (sessionStorage.wiz_funnel === 'wiz_recovery_2_start') {
				logPlausibleEvent({
					n: 'Wiz Recovery 2 Success',
					p: { preferred, welcome_email_app, recovery_1, recovery_2 },
					u: '/'
				});
				sessionStorage.setItem('wiz_funnel', 'wiz_recovery_2_success');
			}
		}
	}

	async function toggleEdit() {
		keepAlive();
		//FIX
		handleDropdown(); //closes all dropdown states
		confirmProviderDelete = showAddNameModal = verifyProvider = verifyProviderAuthority;

		if (editMode) {
			editMode = false;
			sessionStorage.removeItem('isInEditMode');
		} else {
			logPlausibleEvent({ u: '/edit', n: 'action' });
			editMode = true;
			sessionStorage.setItem('isInEditMode', true);
		}
	}

	const makeAuthorityNotification = (account, text) => {
		if (account.slug === 'ethereum') {
			return $_(text, {
				values: {
					provider: account.wallet?.name,
					label: account.user_name || ''
				}
			});
		} else if (account.slug === 'email') {
			return $_(text, {
				values: {
					provider: $_('Email'),
					label: account.user_name || ''
				}
			});
		} else if (account.slug === 'phone') {
			return $_(text, {
				values: {
					provider: $_('Phone'),
					label: account.user_name || ''
				}
			});
		} else {
			return $_(text, {
				values: {
					provider: getDisplay(account.slug),
					label: account.user_name || ''
				}
			});
		}
	};

	async function makePreferred(account) {
		await putAuthority(
			account,
			{ preferred: account.id },
			makeAuthorityNotification(account, '{provider} {label} is now preferred')
		);
	}

	async function makeRecovery(account) {
		await putAuthority(
			account,
			{ recovery: account.id },
			makeAuthorityNotification(account, '{provider} {label} is now a recovery')
		);
	}

	async function removeRecovery(account) {
		await putAuthority(
			account,
			{ no_recovery: account.id },
			makeAuthorityNotification(account, '{provider} {label} has been removed as a recovery')
		);
	}

	const putAuthorityErrors = {
		CANNOT_HAVE_MORE_THAN_ONE_RECOVERY_FROM_SAME_ORG: $_(
			'Cannot have more than one recovery from the same organization'
		)
	};
	async function putAuthority(account, action, notificationText) {
		try {
			const isAccountSocial = !['ethereum', 'email', 'phone'].includes(account.slug);
			const res = await putPreferred(action, {
				redirectPathParam: isAccountSocial, // only send redirect_path param if social provider
				server: account.mastodonServer || null // only send server param if mastodon
			});
			$profileData.profile.accounts = res.accounts;
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			$notification = {
				text: notificationText,
				type: 'success'
			};
		} catch (err) {
			if ([401, 403].includes(err.status)) {
				const json = await err.json();
				if (json.verify) {
					console.info('Subject is not authenticated, showing verify modal.');
					verifyProviderAuthority = { id: account.id, ...json.verify, action };
					if (verifyProviderAuthority.slug === 'ethereum') {
						verifyProviderAuthority.wallet = $profileData.profile?.accounts?.find(
							(i) => i.id === verifyProviderAuthority.id
						).wallet;
					} else if (verifyProviderAuthority.slug === 'mastodon') {
						//Do not show Mastodon dropdown UI - show continue with Mastodon account button
						verifyProviderAuthority.mastodonServer = $profileData.profile?.accounts?.find(
							(i) => i.id === verifyProviderAuthority.id
						).mastodonServer;
						verifyProviderAuthority.user_name = $profileData.profile?.accounts?.find(
							(i) => i.id === verifyProviderAuthority.id
						).user_name;
					}
				} else {
					$notification = {
						text:
							putAuthorityErrors[json?.error?.message] ||
							$_('Something went wrong. Please try again later.'),
						type: 'error'
					};
				}
			} else {
				console.error(err);
			}
		}
	}

	async function continueWithProvider(account, attribute, server) {
		try {
			$showSpinner = true;
			const { redirect } = await postLinkProvider({
				slug: account.slug,
				attribute,
				server
			});
			window.location.href = redirect;
		} catch (err) {
			$showSpinner = false;
			console.error(err);
		}
	}

	function closeLandingModal() {
		showProfileLandingModal = false;
		localStorage.removeItem('showProfileLandingModal');
		//Wait for modal to be popped
		setTimeout(() => {
			$notification = {
				text: `<span>${$_(
					'Learn more about Hellō at'
				)} <a href="https://www.hello.coop" target="_blank" class="underline">hello.coop</a></span>`,
				type: 'success'
			};
		}, 100);
	}
</script>

{#if remoteAuthDone}
	{#if $notification.text}
		<Notification />
	{/if}
	<div class="px-3 mt-12 text-center max-w-md container mx-auto">
		{$_('You can now close this window')}
	</div>
{/if}

{#if Object.keys($profileData).length && $profileData.isPersonalLoggedIn && $profileData.actions?.doneWizardAt}
	{#if showProfileLandingModal}
		<ProfileLandingModal close={closeLandingModal} />
	{/if}

	{#if !remoteAuthDone}
		<TitleBar profile {editMode} on:click={toggleEdit} />
	{/if}

	{#if $notification.text}
		<Notification />
	{/if}

	{#if !$showSpinner}
		<InfoSection {editMode} {toggleEdit} />

		<main class="flex-1 pb-16 overflow-y-auto">
			<div class="px-3 max-w-2xl container mx-auto">
				<PictureSection
					{editMode}
					bind:expanded={dropdownStates.picture}
					{continueWithProvider}
					{handleDropdown}
				/>

				<LanguageSection {editMode} bind:expanded={dropdownStates.language} {handleDropdown} />

				{#if editMode}
					<NameSection />
				{/if}

				<ProviderSection
					{editMode}
					{handleDropdown}
					{continueWithProvider}
					{makePreferred}
					{makeRecovery}
					{removeRecovery}
					bind:dropdownStates
					bind:verifyProvider
					bind:verifyProviderAuthority
				/>

				<ApplicationSection {editMode} />

				<DeviceSection {editMode} />

				<DownloadDataSection {editMode} />
			</div>
		</main>
		<wc-footer use:setAttributes />
	{/if}
{/if}
