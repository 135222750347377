<script>
	import { data as profileData, notification } from '../../stores.js';
	import { DEFAULT_MANAGED_LOGO, DEFAULT_PERSONAL_LOGO } from '../../constants.js';
	import { flip } from 'svelte/animate';
	import { _, locale } from 'svelte-i18n';
	import { slide } from 'svelte/transition';
	import Modal from '../modal/Modal.svelte';
	import dayjs from 'dayjs';
	import relativeTime from 'dayjs/plugin/relativeTime';
	import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
	import 'dayjs/locale/ar';
	import 'dayjs/locale/fr';
	import 'dayjs/locale/es';
	import 'dayjs/locale/hi';
	import 'dayjs/locale/de';
	import RemoveButton from '../button/RemoveButton.svelte';
	import RemoveIconButton from '../button/RemoveIconButton.svelte';
	import { dedupe, updateAccounts } from '../../utils/helper';
	import * as sort from '../../utils/sort';
	import {
		deleteApplication,
		getProfile,
		deleteManagedApplication
	} from '../../utils/api-calls.js';

	dayjs.extend(preParsePostFormat);
	dayjs.extend(relativeTime);

	export let editMode = false;

	const flip_animation_ms = 2000;

	let confirmManagedApplicationDelete, confirmApplicationDelete;

	let removeApplicationAjax = false;
	async function removeApplication() {
		try {
			removeApplicationAjax = confirmApplicationDelete;
			await deleteApplication(confirmApplicationDelete);
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmApplicationDelete = null;
		} catch (err) {
			console.error(err);
			$notification = {
				text: $_('Something went wrong. Please try again later.'),
				type: 'error'
			};
		} finally {
			removeApplicationAjax = null;
		}
	}

	let removeManagedApplicationAjax = false;
	async function removeManagedApplication() {
		try {
			removeManagedApplicationAjax = confirmManagedApplicationDelete;
			await deleteManagedApplication(confirmManagedApplicationDelete);
			$profileData = await getProfile();
			$profileData = updateAccounts($profileData); //add sortrank, sortlabel and unverified accounts to accounts array
			confirmManagedApplicationDelete = null;
		} catch (err) {
			console.error(err);
			$notification = {
				text: $_('Something went wrong. Please try again later.'),
				type: 'error'
			};
		} finally {
			removeManagedApplicationAjax = null;
		}
	}
</script>

<section id="applications" class="mt-10">
	<h2 class="font-medium text-lg">{$_('Applications')}</h2>

	{#if $profileData.profile.applications?.length}
		<div class="pl-6">
			<h3
				class="mt-3 inline-flex items-center w-full h-12 rounded-md bg-charcoal bg-opacity-10 dark:bg-opacity-100 overflow-hidden px-4"
			>
				<span class="managed-rounded-square-sm bg-transparent">
					<img
						src={DEFAULT_PERSONAL_LOGO}
						class="w-4.5 max-h-[18px] object-contain dark:invert"
						alt="personal account application releases"
					/>
				</span>
				<span class="ml-2 px-0.5">{$_('Personal')}</span>
			</h3>
		</div>
		<ul class="pl-6 divide-y divide-charcoal/25 dark:divide-white/25">
			{#each dedupe([...$profileData.profile.applications], 'id').sort(sort.apps) as application (application.id)}
				<li
					class="flex relative items-end px-3.5 py-3"
					animate:flip={{ duration: flip_animation_ms }}
					transition:slide|local
				>
					{#if confirmApplicationDelete && confirmApplicationDelete === application.id}
						<Modal
							position="left"
							class="md:min-w-[22rem] top-10"
							on:close={() => (confirmApplicationDelete = null)}
						>
							<div class="flex flex-col md:flex-row items-center justify-between">
								<span>{$_('Confirm Action')}</span>
								<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
									<button
										on:click={() => (confirmApplicationDelete = null)}
										class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
									>
									<RemoveButton
										on:click={removeApplication}
										isLoading={removeApplicationAjax === application.id}
										value="Forget"
									/>
								</div>
							</div>
						</Modal>
					{/if}

					<div class="w-2 flex-shrink-0 mb-7">
						{#if editMode}
							<RemoveIconButton on:click={() => (confirmApplicationDelete = application.id)} />
						{/if}
					</div>

					<div class="w-6 h-6 inline-flex items-center justify-center flex-shrink-0" />

					<div
						class="{$locale && $locale.startsWith('ar')
							? 'mr-2 items-start'
							: 'ml-2'} flex flex-col"
					>
						{#if application.image_uri || application.dark_image_uri}
							<picture>
								<source
									srcset={application.dark_image_uri || application.image_uri}
									media="(prefers-color-scheme: dark)"
								/>
								<img
									src={application.image_uri}
									alt={application.name}
									class="max-w-[8rem] max-h-[1.8rem] object-contain object-left"
								/>
							</picture>
						{/if}

						<span class="mt-1">
							{application.name}
						</span>

						<span class="text-xs italic">
							{$_('First login {date}', {
								values: {
									date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
								}
							})}

							{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country}
								•
								{application.firstRelease?.location?.City
									? `${application.firstRelease?.location?.City},`
									: ''}
								{application.firstRelease?.location?.Country}
							{/if}
						</span>
						<span class="text-xs italic">
							{#if application.lastRelease?.at}
								{$_('Last login {date}', {
									values: {
										date: dayjs(application.lastRelease?.at).locale($locale).fromNow()
									}
								})}
							{:else}
								{$_('Last login {date}', {
									values: {
										date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
									}
								})}
							{/if}

							{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country || application.lastRelease?.location?.City || application.lastRelease?.location?.Country}
								•
								{application.lastRelease?.location.City
									? `${application.lastRelease?.location?.City},`
									: `${application.firstRelease?.location?.City},`}
								{application.lastRelease?.location?.Country ||
									application.firstRelease?.location?.Country}
							{/if}
						</span>
					</div>
				</li>
			{/each}
		</ul>
	{/if}

	{#if $profileData.profile.managedApplications?.length}
		<div class="mb-4 pl-6">
			{#each $profileData.profile.managedApplications as managedApplication (managedApplication.subject.id)}
				<h3
					class="mt-3 inline-flex items-center w-full h-12 rounded-md px-4 bg-charcoal bg-opacity-10 dark:bg-opacity-100 overflow-hidden"
				>
					<span class="managed-rounded-square-sm">
						<img
							src={managedApplication.subject.logo || DEFAULT_MANAGED_LOGO}
							class="w-4.5 max-h-[18px] object-contain"
							alt={managedApplication.subject.user_name}
						/>
					</span>
					<!-- <span
						class="flex-shrink-0 bg-charcoal dark:bg-transparent w-10 h-10 flex items-center justify-center"
					>
					</span> -->
					<span class="ml-2 px-0.5">{managedApplication.subject.user_name}</span>
				</h3>
				<ul class="divide-y divide-charcoal/25 dark:divide-white/25">
					{#each managedApplication.releases as application (application.id)}
						<li
							class="flex relative items-end px-3.5 py-3"
							animate:flip={{ duration: flip_animation_ms }}
							transition:slide|local
						>
							{#if confirmManagedApplicationDelete && confirmManagedApplicationDelete?.id === application.id && confirmManagedApplicationDelete?.sub === application.sub}
								<Modal
									position="left"
									class="md:min-w-[22rem] top-10"
									on:close={() => (confirmManagedApplicationDelete = null)}
								>
									<div class="flex flex-col md:flex-row items-center justify-between">
										<span>{$_('Confirm Action')}</span>
										<div class="flex items-center mt-4 gap-x-4 md:mt-0 md:space-x-2">
											<button
												on:click={() => (confirmManagedApplicationDelete = null)}
												class="btn-border h-9 w-20 px-0 text-sm">{$_('Cancel')}</button
											>
											<RemoveButton
												on:click={removeManagedApplication}
												isLoading={removeManagedApplicationAjax?.id === application.id &&
													removeManagedApplicationAjax?.sub === application.sub}
												value="Forget"
											/>
										</div>
									</div>
								</Modal>
							{/if}

							<div class="w-2 flex-shrink-0 mb-7">
								{#if editMode}
									<RemoveIconButton
										on:click={() =>
											(confirmManagedApplicationDelete = {
												id: application.id,
												sub: application.sub
											})}
									/>
								{/if}
							</div>

							<div class="w-6 h-6 inline-flex items-center justify-center flex-shrink-0" />

							<div
								class="{$locale && $locale.startsWith('ar')
									? 'mr-2 items-start'
									: 'ml-2'} flex flex-col"
							>
								{#if application.image_uri || application.dark_image_uri}
									<picture>
										<source
											srcset={application.dark_image_uri || application.image_uri}
											media="(prefers-color-scheme: dark)"
										/>
										<img
											src={application.image_uri}
											alt={application.name}
											class="max-w-[8rem] max-h-[1.8rem] object-contain object-left"
										/>
									</picture>
								{/if}

								<span class="mt-1">
									{application.name}
								</span>

								<span class="text-xs italic">
									{$_('First login {date}', {
										values: {
											date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
										}
									})}

									{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country}
										•
										{application.firstRelease?.location?.City
											? `${application.firstRelease?.location?.City},`
											: ''}
										{application.firstRelease?.location?.Country}
									{/if}
								</span>
								<span class="text-xs italic">
									{#if application.lastRelease?.at}
										{$_('Last login {date}', {
											values: {
												date: dayjs(application.lastRelease?.at).locale($locale).fromNow()
											}
										})}
									{:else}
										{$_('Last login {date}', {
											values: {
												date: dayjs(application.firstRelease?.at).locale($locale).fromNow()
											}
										})}
									{/if}

									{#if application.firstRelease?.location?.City || application.firstRelease?.location?.Country || application.lastRelease?.location?.City || application.lastRelease?.location?.Country}
										•
										{application.lastRelease?.location.City
											? `${application.lastRelease?.location?.City},`
											: `${application.firstRelease?.location?.City},`}
										{application.lastRelease?.location?.Country ||
											application.firstRelease?.location?.Country}
									{/if}
								</span>
							</div>
						</li>
					{/each}
				</ul>
			{/each}
		</div>
	{/if}

	{#if !$profileData.profile.applications?.length && !$profileData.profile.managedApplications?.length}
		<span
			class="text-fallback text-sm mt-4 block {$locale && $locale.startsWith('ar')
				? 'mr-6'
				: 'ml-6'}">{$_('No applications')}</span
		>
	{/if}
</section>
